<template>
  <div id="order-detail">
    <Navigation />
    <v-main class="content mb-9">
      <v-container fluid>
        <v-card style="border-radius: 2px" color="#383E45">
          <v-container fluid class="py-5 px-6">
            <HeaderPage
              :title="
                `รายการสินค้าออเดอร์ ${generateOrderNumber(order[0].order_id)}`
              "
            />

            <div class="wrapper-table mb-1 mt-3">
              <table class="table-table">
                <tr class="table-head">
                  <th>#</th>
                  <th>ชื่อสินค้า</th>
                  <th>จำนวน</th>
                  <th>ประเภทสินค้า</th>
                </tr>
                <tr
                  class="table-body"
                  v-for="(data, index) in order"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.product_name }}</td>
                  <td>{{ data.quantity }}</td>
                  <td>{{ data.product_type }}</td>
                </tr>
              </table>
            </div>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
    <FooterAdmin />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import HeaderPage from '@/components/HeaderPage.vue';
import FooterAdmin from '@/components/FooterAdmin.vue';

import OrderService from '@/services/Order.service.js';

import Swal from 'sweetalert2';
export default {
  name: 'order-detail',
  components: {
    Navigation,
    HeaderPage,
    FooterAdmin,
  },
  data() {
    return {
      order: [],
    };
  },
  created() {
    this.getOrderDetail();
  },
  methods: {
    generateOrderNumber(number) {
      let orderID = number.toString();
      const maxLength = 8;
      const currentLength = maxLength - orderID.length;
      const prefix = 'OD';
      const prefixLenght = Array.from({ length: currentLength }, () => '0');
      const order = prefixLenght.join('');
      return prefix + order + orderID;
    },
    async getOrderDetail() {
      try {
        const response = await OrderService.findOne(this.$route.params.orderId);
        console.log(response);
        this.order = response.order;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  },
};
</script>

<style></style>
