import axios from 'axios';
import authHeader from './auth-header';
class Order {
  insert(order) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post('/management/order', order, {
          headers: authHeader(),
        });
        return resolve(response.data);
      } catch (error) {
        return reject(error.response.data);
      }
    });
  }

  async findAll({ search, date, page, limit }) {
    try {
      const { data } = await axios.get(
        `/management/orders?search=${search}&date=${date}&page=${page}&limit=${limit}`,
        {
          headers: authHeader(),
        }
      );
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async findOne(orderId) {
    try {
      const { data } = await axios.get(`/management/order?orderId=${orderId}`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async updateStatus(orderId) {
    try {
      const { data } = await axios.patch(
        `/management/order-status`,
        { order_id: orderId },
        {
          headers: authHeader(),
        }
      );
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
  async findOne(orderId) {
    try {
      const { data } = await axios.get(`/management/order?orderId=${orderId}`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async delete(orderId) {
    try {
      const { data } = await axios.delete(`/management/order`, {
        data: { orderId },
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async updateOrder(order) {
    try {
      const { data } = await axios.patch(`/management/order`, order, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }

  async updateProductOrderAction(action) {
    try {
      const { data } = await axios.patch(
        `/management/product-order/action`,
        action,
        {
          headers: authHeader(),
        }
      );
      return data;
    } catch (error) {
      throw error.response.data;
    }
  }
}
export default new Order();
